import { Routes } from '@angular/router';
import { LayoutComponent } from '@shared/layout/layout.component';
import { featureGuard } from '@shared/route-guards/feature.guard';
import { accessGuard } from './pages/auth/guards/access.guard';
import { authGuard } from './pages/auth/guards/auth.guard';

export default [
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.routes'),
    canMatch: [authGuard]
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('./pages/dashboard/dashboard.component'),
        title: 'Dashboard',
        data: { feature: 'home' },
        canMatch: [featureGuard]
      },
      {
        path: 'kyc',
        loadChildren: () => import('./pages/kyc/kyc.routes'),
        data: { feature: 'identityVerification' },
        canMatch: [featureGuard]
      },
      {
        path: 'vouchers',
        loadComponent: () => import('./pages/vouchers/vouchers.component'),
        title: 'Vouchers',
        data: { feature: 'vouchers' },
        canMatch: [featureGuard]
      },
      {
        path: 'balance-history',
        loadComponent: () => import('./pages/balance-history/balance-history.component'),
        title: 'Balance History',
        data: { feature: 'balanceHistory' },
        canMatch: [featureGuard]
      },
      {
        path: 'crypto-transactions',
        loadComponent: () => import('./pages/crypto-transactions/crypto-transactions.component'),
        title: 'Crypto Transactions',
        data: { feature: 'cryptoTransactions' },
        canMatch: [featureGuard]
      },
      {
        path: 'deposit',
        loadComponent: () => import('./pages/add-money/add-money.component'),
        title: 'Add Money',
        data: { feature: 'deposit' },
        canMatch: [featureGuard]
      },
      { path: '', pathMatch: 'full', redirectTo: 'vouchers' },
      {
        path: '**',
        loadComponent: () => import('./pages/not-found/not-found.component'),
        title: 'Not Found 404',
      },
    ],
    canMatch: [accessGuard]
  },
] as Routes;
